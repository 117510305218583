import React from 'react';
import clock from '../../static/images/neon-clock.png';
import dot from '../../static/images/dot.svg';
import bg2 from '../../static/images/bg2.jpg';
import bg4 from '../../static/images/bg4.jpg';

interface MainProps { }

const Main: React.FC<MainProps> = () => {
    return (
        <div>
            <div className="intro">
                <div className="_container">
                    <div className="intro__inner">
                        <div className="intro__title">Работай по своим правилам:</div>
                        <div className="intro__subtitle">Зарабатывай от 500 000 руб. до 10 млн в месяц</div>
                        <div className="intro__text">
                            <ul>
                                <li>- Свободный график</li>
                                <li>- Круглосуточная охрана</li>
                                <li>- Путешествия по миру</li>
                                <li>- Анонимно и Безопасно</li>
                                <li>- Работа есть абсолютно для каждой</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <section className="works">
                <div className="works__inner _container">
                    <div className="section__title">
                        Работа для девушек в самом лучшем и безопасном эскорт сообществе:
                    </div>
                    <div className="works__cards">
                        <div className="works__card">
                            <img src={clock} alt="" />
                            <div className="works__card-title">
                                - свободный график
                            </div>
                        </div>
                        <div className="works__card">
                            <img src={clock} alt="" />
                            <div className="works__card-title">
                                - свободный график
                            </div>
                        </div>
                        <div className="works__card">
                            <img src={clock} alt="" />
                            <div className="works__card-title">
                                - свободный график
                            </div>
                        </div>
                        <div className="works__card">
                            <img src={clock} alt="" />
                            <div className="works__card-title">
                                - свободный график
                            </div>
                        </div>
                        <div className="works__card">
                            <img src={clock} alt="" />
                            <div className="works__card-title">
                                - свободный график
                            </div>
                        </div>
                        <div className="works__card">
                            <img src={clock} alt="" />
                            <div className="works__card-title">
                                - свободный график
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="what">
                <div className="what__inner _container">
                    <div className="what__texts">
                        <div className="section__title">Что нужно делать:</div>
                        <div className="what__text">
                            <ul>
                                <li>сопровождение на мероприятиях</li>
                                <li>встречи</li>
                                <li>путешествия за границу</li>
                            </ul>
                        </div>
                    </div>
                    <div className="what__image">
                        <img src={bg2} alt="" />
                    </div>
                </div>
            </section>

            <section className="how">
                <div className="_container">
                    <div className="how__inner">
                        <div className="section__title">Как все устроено:</div>
                        <div className="how__cards">
                            <div className="how__card">
                                <div className="how__number">1</div>
                                <div className="how__text">
                                    Связываетесь с менеджером – рассказываем, как все устроено (анонимно через Telegram, можете не показывать лицо и скрыть свои контактные данные)
                                </div>
                            </div>
                            <div className="how__card">
                                <div className="how__number">2</div>
                                <div className="how__text">
                                    Связываетесь с менеджером – рассказываем, как все устроено (анонимно через Telegram, можете не показывать лицо и скрыть свои контактные данные)
                                </div>
                            </div>
                            <div className="how__card">
                                <div className="how__number">3</div>
                                <div className="how__text">
                                    Связываетесь с менеджером – рассказываем, как все устроено (анонимно через Telegram, можете не показывать лицо и скрыть свои контактные данные)
                                </div>
                            </div>
                            <div className="how__card">
                                <div className="how__number">4</div>
                                <div className="how__text">
                                    Связываетесь с менеджером – рассказываем, как все устроено (анонимно через Telegram, можете не показывать лицо и скрыть свои контактные данные)
                                </div>
                            </div>
                            <div className="how__card">
                                <div className="how__number">5</div>
                                <div className="how__text">
                                    Связываетесь с менеджером – рассказываем, как все устроено (анонимно через Telegram, можете не показывать лицо и скрыть свои контактные данные)
                                </div>
                            </div>
                            <div className="how__card">
                                <div className="how__number">6</div>
                                <div className="how__text">
                                    Связываетесь с менеджером – рассказываем, как все устроено (анонимно через Telegram, можете не показывать лицо и скрыть свои контактные данные)
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="security">
                <div className="_container">
                    <div className="security__inner">
                        <div className="section__title">
                            Анонимность и Безопасность
                        </div>
                        <div className="security__cards">
                            <div className="security__card">
                                <li><img src={dot} alt="" /> Только у Нас абсолютно Анонимно и Безопасно</li>
                            </div>
                            <div className="security__card">
                                <li><img src={dot} alt="" /> Только у Нас абсолютно Анонимно и Безопасно</li>
                            </div>
                            <div className="security__card">
                                <li><img src={dot} alt="" /> Только у Нас абсолютно Анонимно и Безопасно</li>
                            </div>
                            <div className="security__card">
                                <li><img src={dot} alt="" /> Только у Нас абсолютно Анонимно и Безопасно</li>
                            </div>
                            <div className="security__card">
                                <li><img src={dot} alt="" /> Только у Нас абсолютно Анонимно и Безопасно</li>
                            </div>
                            <div className="security__card">
                                <li><img src={dot} alt="" /> Только у Нас абсолютно Анонимно и Безопасно</li>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact">
                <div className="contact__inner _container">
                    <div className="contact__image">
                        <img src={bg4} alt="" />
                    </div>
                    <div className="contact__texts">
                        <div className="section__title">
                            Связаться с нами
                        </div>
                        <div className="contact__text">
                            <div>Безопасно и Анонимно напишите менеджеру в Telegram</div>
                            <div>Ответим на все интересующие вопросы </div>
                        </div>
                        <button className="btn btn-purple">
                            свяжитесь с нами
                        </button>
                    </div>
                </div>
            </section>

            <section></section>
        </div>
    );
}

export default Main;
