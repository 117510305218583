import React from 'react';

const Footer = () => {
    return (
        <div>
            <header className="header">
                <div className="header__inner _container">
                    <div className="header__title">
                        LOGO
                    </div>
                    <nav className="nav">
                        <a href="#!" className="nav__link">Работа</a>
                        <a href="#!" className="nav__link">Как все устроено</a>
                        <a href="#!" className="nav__link">Безопасность</a>
                        <a href="#!" className="nav__link">Связаться с нами</a>
                    </nav>
                </div>
            </header>

        </div>
    );
}

export default Footer;
