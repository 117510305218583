import React from 'react';

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
    const openMenu = () => {
        const menu = document.querySelector('.menu');
        if (menu) {
            menu.classList.add('active');
        }
    };

    const closeMenu = () => {
        const menu = document.querySelector('.menu');
        if (menu) {
            menu.classList.remove('active');
        }
    };

    return (
        <div>
            <header className="header top pc">
                <div className="header__inner _container">
                    <div className="header__title">
                        LOGO
                    </div>
                    <nav className="nav">
                        <a href="#!" className="nav__link">Работа</a>
                        <a href="#!" className="nav__link">Как все устроено</a>
                        <a href="#!" className="nav__link">Безопасность</a>
                        <a href="#!" className="nav__link">Связаться с нами</a>
                    </nav>
                </div>
            </header>
            <header className="header top mobile">
                <div className="header__inner _container">
                    <div className="header__title">
                        LOGO
                    </div>
                    <div className="header__burger" onClick={openMenu}>
                        <svg width={39} height={22} viewBox="0 0 39 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.470825" y="0.478271" width="38.5292" height={3} fill="#fff" />
                            <rect x="0.470825" y="9.47827" width="38.5292" height={3} fill="#fff" />
                            <rect x="0.470825" y="18.4783" width="38.5292" height={3} fill="#fff" />
                        </svg>
                    </div>
                </div>
                <div className="menu">
                    <div className="menu__close" onClick={closeMenu}>×</div>
                    <div className="menu__inner">
                        <div className="header__logo">
                            LOGO
                        </div>
                        <nav className="menu-nav">
                            <a href="#!" className="nav__link">Работа</a>
                            <a href="#!" className="nav__link">Как все устроено</a>
                            <a href="#!" className="nav__link">Безопасность</a>
                            <a href="#!" className="nav__link">Связаться с нами</a>
                        </nav>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Header;
